import React from 'react'
import { Container, Wrapper } from '../styles/globalstyles'
import VeranstaltungsreiheForm from '../components/forms/veranstaltungsreihe-form'
import SeminarForm from '../components/forms/seminar-form'
import EventForm from '../components/forms/event-form'
import OnlineEventForm from '../components/forms/online-event-form'

const EventFormTemplate = ({pageContext}) => {
console.log(pageContext)
  return (
    <Wrapper>
        <Container>
            <div css={{marginTop: '100px'}}>
                <h1>
                    Anmeldung: {pageContext.titel}
                </h1>
            </div>
            <div css={{marginBottom: '12px'}}>
                {pageContext.type === 'veranstaltungsreihe' &&  <VeranstaltungsreiheForm veranstaltungen={pageContext.veranstaltungen} veranstaltungsModule={pageContext.veranstaltungen} kalender={pageContext.kalender} /> }
                {pageContext.type === 'tagesseminar' &&  <SeminarForm termine={pageContext.termine} titel={pageContext.titel} /> }
                {pageContext.type === 'abendveranstaltung' &&  <EventForm termine={pageContext.termine} titel={pageContext.titel} /> }
                {pageContext.type === 'online-event' &&  <OnlineEventForm termine={pageContext.termine} titel={pageContext.titel} /> }
                {pageContext.type === 'landingpage' &&  <VeranstaltungsreiheForm veranstaltungen={pageContext.veranstaltungen} veranstaltungsModule={pageContext.veranstaltungen} kalender={pageContext.kalender} /> }
            </div>
        </Container>
    </Wrapper>
  )
}

export default EventFormTemplate