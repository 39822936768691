import React, { useState } from "react"
import { Formik } from "formik"
import { Label, FormDiv, Textfield, Selector, Checkbox } from "./styles"
import { buttonmedium } from "../../styles/buttons";
import * as Yup from 'yup';
import dayjs from "dayjs";

const SignupSchemaWithoutDates = Yup.object().shape({
    vorname: Yup.string()
      .min(2, 'Vorname zu kurz')
      .max(50, 'Vorname zu lang')
      .required('Vorname wird benötigt'),
    nachname: Yup.string()
      .min(2, 'Nachname zu kurz')
      .max(50, 'Nachname zu lang')
      .required('Nachname würd benötigt'),
    firma: Yup.string()
      .required('Firmenadresse wird benötigt'),
    strasse: Yup.string()
      .required('Straße und Hausnummer werden benötigt'),
    ort: Yup.string()
      .required('Postleitzahl und Stadt werden benötigt'),
    telefon: Yup.string()
      .required('Telefonnummer wird benötigt'),
    email: Yup.string()
      .email('Ungültige E-Mail Adresse')
      .required('E-Mail Adresse wird benötigt'),
    agb: Yup.boolean().oneOf([true], 'Die AGB müssen bestätigt werden'),
    datenschutz: Yup.boolean().oneOf([true],'Die Datenschutzerklärung muss bestätigt werden'),
  });


const SignupSchema = Yup.object().shape({
    termin: Yup.mixed()
    .required("Bitte einen Termin auswählen"),
    vorname: Yup.string()
      .min(2, 'Vorname zu kurz')
      .max(50, 'Vorname zu lang')
      .required('Vorname wird benötigt'),
    nachname: Yup.string()
      .min(2, 'Nachname zu kurz')
      .max(50, 'Nachname zu lang')
      .required('Nachname würd benötigt'),
    firma: Yup.string()
      .required('Firmenadresse wird benötigt'),
    strasse: Yup.string()
      .required('Straße und Hausnummer werden benötigt'),
    ort: Yup.string()
      .required('Postleitzahl und Stadt werden benötigt'),
    telefon: Yup.string()
      .required('Telefonnummer wird benötigt'),
    email: Yup.string()
      .email('Ungültige E-Mail Adresse')
      .required('E-Mail Adresse wird benötigt'),
    agb: Yup.boolean().oneOf([true], 'Die AGB müssen bestätigt werden'),
    datenschutz: Yup.boolean().oneOf([true],'Die Datenschutzerklärung muss bestätigt werden'),
  });

const EventForm = ({titel, termine}) => {
    const [serverState, setServerState] = useState()
    let dates = termine || []
    const handleServerResponse = (ok, msg) => {
        setServerState({ ok, msg })
          }
    const handleOnSubmit = (values, actions) => {
      
      fetch('https://usebasin.com/f/58973b04a7fb', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      .then(response => {
        actions.setSubmitting(false)
        actions.resetForm()
        handleServerResponse(true, "Danke für Ihre Anmeldung.")
      }).catch(error => {
        actions.setSubmitting(false)
        handleServerResponse(false, "Da ist leider etwas schief gelaufgen")
      })
    }

    return(
        <Formik
            initialValues={{
                event: titel,
                termin: "",
                vorname: "",
                nachname: "",
                telefon: "",
                email: "",
                firma: "",
                strasse: "",
                ort: "",
                newsletter: false,
                agb: false,
                datenschutz: false,
            }}
                validationSchema={dates.length > 0 ? SignupSchema : SignupSchemaWithoutDates}
                onSubmit={handleOnSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                >
                {props => {
                const {
                    values,
                    isSubmitting,
                    handleSubmit,
                    handleChange
                } = props
                return (
                <form onSubmit={handleSubmit}>
                    <FormDiv>
                   {dates.length > 0 && 
                   <>
                   <Label>Termine</Label>
                    <Selector
                        name="termin"
                        value={values.termin}
                        placeHolder="Termine"
                        onChange={handleChange}
                        style={{display: "block"}}
                        >
                        <option value="select" key="0" label="Einen Termin wählen" />
                        { dates && dates.map((value, index) => {
                            let d = new Date(value.termin)
                            let date = dayjs(d).locale("de").format("DD.MM.YYYY") 
                            let ort = value.veranstaltungsort
                            return (
                                <option key={index+1} value={date +  " - " + ort} label={date +  " - " + ort} />
                            )
                        }
                        )}
                    </Selector>
                    </>
                    }
                    </FormDiv>
                    <Textfield
                      placeholder="Vorname"
                      name="vorname"
                      type="text"
                      label="Vorname"
                    ></Textfield>
                    <FormDiv>
                    <Textfield
                      placeholder="Nachname"
                      type="text"
                      name="nachname"
                      label="Nachname"
                    />
                    </FormDiv>
                    <FormDiv>
                    <Textfield
                        placeholder="Firmenname"
                        type="text"
                        name="firma"
                        label="Firmenname"
                    />
                    </FormDiv>
                    <FormDiv>
                    <Textfield
                        placeholder="Straße und Hausnummer"
                        type="text"
                        name="strasse"
                        label="Straße und Hausnummer"
                    />
                    </FormDiv>
                    <FormDiv>
                    <Textfield
                        placeholder="Postleitzahl und Stadt"
                        type="text"
                        name="ort"
                        label="Postleitzahl und Stadt"
                    />
                    </FormDiv>
                    <FormDiv>
                    <Textfield
                        placeholder="Telefonnummer"
                        type="text"
                        name="telefon"
                        label="Telefonnummer"
                    />
                    </FormDiv>

                    <FormDiv>
                    <Textfield
                        placeholder="E-Mail"
                        type="text"
                        name="email"
                        label="E-Mail"
                    />
                    </FormDiv>
                <Checkbox 
                    name="newsletter"
                    label="Ich bin einverstanden, dass Sie mich über Neuigkeiten informieren" />
                <Checkbox 
                    name="agb"
                    label="Ich akzeptiere die allgemeinen Geschäftsbedinungen"
                    />
                <Checkbox 
                    name="datenschutz"
                    label="Ich akzeptiere die Datenschutzbedingungen"
                    />
                    {(serverState) ? <h6 className={!serverState.ok ? "errorMsg" : ""}>
                        {serverState.msg}
                        </h6> : (isSubmitting ? <h6>wird gesendet</h6> : <button type='submit' css={[buttonmedium, { marginTop: "1.2rem"}]} className="buttonShadow" >Jetzt anmelden </button> )
                        }
                </form>
                )
            }}
        </Formik>

    )

}

export default EventForm;