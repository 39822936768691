import React, { useState } from "react";
import { dark} from "../../styles/globalstyles";
import { Formik } from "formik";
import { Input, Label, FormDiv, Selector, Textfield, Checkbox } from "./styles";
import { buttonmedium } from "../../styles/buttons";
import * as Yup from 'yup';


const SignupSchema = Yup.object().shape({
    termin: Yup.mixed()
    .required("Bitte einen Termin auswählen"),
    vorname: Yup.string()
      .min(2, 'Vorname zu kurz')
      .max(50, 'Vorname zu lang')
      .required('Vorname wird benötigt'),
    nachname: Yup.string()
      .min(2, 'Nachname zu kurz')
      .max(50, 'Nachname zu lang')
      .required('Nachname würd benötigt'),
    firma: Yup.string()
      .required('Firmenadresse wird benötigt'),
    strasse: Yup.string()
      .required('Straße und Hausnummer werden benötigt'),
    ort: Yup.string()
      .required('Postleitzahl und Stadt werden benötigt'),
    telefon: Yup.string()
      .required('Telefonnummer wird benötigt'),
    email: Yup.string()
      .email('Ungültige E-Mail Adresse')
      .required('E-Mail Adresse wird benötigt'),
    agb: Yup.boolean().oneOf([true], 'Die AGB müssen bestätigt werden'),
    datenschutz: Yup.boolean().oneOf([true],'Die Datenschutzerklärung muss bestätigt werden'),
  });

const SeminarForm = ({titel, termine}) => {
    const [serverState, setServerState] = useState()
    let dates = termine[0]
    console.log({dates})
    const handleServerResponse = (ok, msg) => {
        setServerState({ ok, msg })
          }
    const handleOnSubmit = (values, actions) => {
        fetch('https://usebasin.com/f/ab6a55eea64c', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      .then(response => {
        actions.setSubmitting(false)
        actions.resetForm()
        handleServerResponse(true, "Danke für Ihre Anmeldung.")
      }).catch(error => {
        actions.setSubmitting(false)
        handleServerResponse(false, "Da ist leider etwas schief gelaufgen")
      })
    }
    return(
        <Formik
            initialValues={{
                event: titel,
                termin: "",
                vorname: "",
                nachname: "",
                telefon: "",
                email: "",
                firma: "",
                strasse: "",
                ort: "",
                newsletter: false,
                agb: false,
                datenschutz: false,
            }}
                validationSchema={SignupSchema}
                onSubmit={handleOnSubmit}
                validateOnBlur={false}
                validateOnChange={  false}
                >
                {props => {
                const {
                    values,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                } = props
                return (
                <form onSubmit={handleSubmit}>
                  <h3 css={{color: dark}}>Jetzt anmelden</h3>
                    <FormDiv>
                    <Label>Termine</Label>
                    <Selector
                        name="termin"
                        value={values.termin}
                        placeHolder="Termin"
                        onChange={handleChange}
                    >
                        <option key="a" value="select" label="Einen Termin wählen" />
                        {dates.termine.map((value, index) => {
                            return (
                            <option key={index+1}>
                                {value}
                            </option>
                            )
                        }
                        )}
                    </Selector>
                    </FormDiv>
                    <Textfield
                        placeholder="Vorname"
                        name="vorname"
                        type="text"
                        label="Vorname"
                    ></Textfield>
                    <FormDiv>
                    <Textfield
                        placeholder="Nachname"
                        type="text"
                        name="nachname"
                        label="Nachname"
                    />
                    </FormDiv>
                    <FormDiv>
                    <Textfield
                        placeholder="Firmenname"
                        type="text"
                        name="firma"
                        label="Firmenname"
                    />
                    </FormDiv>
                    <FormDiv>
                    <Textfield
                        placeholder="Straße und Hausnummer"
                        type="text"
                        name="strasse"
                        label="Straße und Hausnummer"
                    />
                    </FormDiv>
                    <FormDiv>
                    <Textfield
                        placeholder="Postleitzahl und Stadt"
                        type="text"
                        name="ort"
                        label="Postleitzahl und Stadt"
                    />
                    </FormDiv>
                    <FormDiv>
                    <Textfield
                        placeholder="Telefonnummer"
                        type="text"
                        name="telefon"
                        label="Telefonnummer"
                    />
                    </FormDiv>
                    <FormDiv>
                    <Textfield
                        placeholder="E-Mail"
                        type="text"
                        name="email"
                        label="E-Mail"
                    />
                    </FormDiv>
                    <Checkbox 
                      name="newsletter"
                      label="Ich bin einverstanden, dass Sie mich über Neuigkeiten informieren" 
                      />
                    <Checkbox 
                      label="Ich akzepiere die allgemeinen Geschäftsbedinungen"
                      name="agb" 
                      />
                    <Checkbox 
                      label="Ich akzeptiere die Datenschutzbedingungen"
                      name="datenschutz" 
                      />
                    {(serverState) ? <h6 className={!serverState.ok ? "errorMsg" : ""}>
                        {serverState.msg}
                        </h6> : (isSubmitting ? <h6>wird gesendet</h6> : <button type="submit" css={[buttonmedium,{ marginTop: "1.2rem"}]} className="buttonShadow" >Jetzt anmelden </button> )
                        }
                </form>
                )
            }}
        </Formik>

    )

}

export default SeminarForm;